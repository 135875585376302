import _ from 'lodash'

export default {
  data() {
    return {
      socialNetworksIcons: {
        facebook: require('@icon/social/facebook.svg'),
        instagram: require('@icon/social/instagram.svg'),
        linkedin: require('@icon/social/linkedin.svg'),
        pinterest: require('@icon/social/pinterest.svg'),
        twitter: require('@icon/social/twitter.svg'),
        vimeo: require('@icon/social/vimeo.svg'),
        youtube: require('@icon/social/youtube.svg'),
      },
      socialNetworkLinks: [],
    }
  },
  mounted() {
    this.socialNetworkLinks = _.get(this.$store, 'state.globals.footer.social_links')
  },
}
