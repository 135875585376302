var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"Carousel"},[_c('div',{directives:[{name:"touch",rawName:"v-touch:swipe",value:(_vm.swipeHandler),expression:"swipeHandler",arg:"swipe"}],ref:"wrapper",staticClass:"Carousel__wrapper",class:{'shake': _vm.shakeItem}},[_c('div',{ref:"row",staticClass:"row flex-nowrap",class:{'row-90': _vm.showPartOfNextProduct}},[_vm._t("default")],2)]),(_vm.carouselHasContent)?_c('div',{staticClass:"Carousel__control Carousel__control--left",class:{
      'd-none': !_vm.$themeSettings.components.Carousel.showNavigation,
      'd-lg-flex': !_vm.$themeSettings.components.Carousel.showNavigationOnMobile,
      'Carousel__control--disabled': _vm.isStart
    },on:{"click":function($event){!_vm.isStart && _vm.prev()}}},[_c('div',{staticClass:"Carousel__controlIconWrapper"},[_c('svgicon',{staticClass:"svg-down",attrs:{"icon":require('@icon/right-chevron.svg?raw')}})],1)]):_vm._e(),(_vm.carouselHasContent)?_c('div',{staticClass:"Carousel__control Carousel__control--right",class:{
      'd-none': !_vm.$themeSettings.components.Carousel.showNavigation,
      'd-lg-flex': !_vm.$themeSettings.components.Carousel.showNavigationOnMobile,
      'Carousel__control--disabled': _vm.isEnd
    },on:{"click":function($event){!_vm.isEnd && _vm.next()}}},[_c('div',{staticClass:"Carousel__controlIconWrapper"},[_c('svgicon',{attrs:{"icon":require('@icon/right-chevron.svg?raw')}})],1)]):_vm._e(),(_vm.$themeSettings.components.Carousel.showPagination && _vm.carouselHasContent && _vm.paginationItemsCount)?_c('div',{staticClass:"Carousel__pagination"},_vm._l((_vm.paginationItemsCount),function(i){return _c('div',{key:`${_vm._uid}_${i}`,staticClass:"Carousel__paginationItem",class:{'Carousel__paginationItem--active': i === _vm.activeIndex + 1}})}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }