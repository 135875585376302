export default {
  props: {
    columnClass: {
      type: String,
      default: '',
    },
    showContact: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FacebookWidgetPage: () => import('@theme/components/utils/FacebookWidgetPage/FacebookWidgetPageLazy.vue'),
    FooterContact: () => import('@theme/components/shop/FooterContact'),
  },
}
