import _ from 'lodash'

const NUM_OF_PAGER_BUTTONS = 2

export default {
  props: {
    url: String,
    buttonLoadMoreAriaLabel: {
      type: String,
      default() {
        return this.$t('Pagination.buttonLoadMore.ariaLabel')
      },
    },
    buttonLoadMoreStyle: {
      type: String,
      default() {
        return this.$themeSettings.components.Pagination.button.style
      },
    },
    buttonLoadMoreText: {
      type: String,
      default() {
        return this.$t('Pagination.buttonLoadMore.text')
      },
    },
    buttonLoadMoreVisible: {
      type: Boolean,
      default() {
        return this.$themeSettings.components.Pagination.button.enable
      },
    },
    buttonLoadMoreNextToPagination: {
      type: Boolean,
      default: false,
    },
    urlParameterName: {
      type: String,
      default: 'p',
    },
    page: {
      type: Number,
      required: true,
    },
    paginationTargetArea: {
      type: String,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    scrollToId: String,
  },
  computed: {
    pages() {
      return _.range(this.pagesStart - 1, this.pagesEnd)
    },
    current() {
      return this.page
    },
    pagesStart() {
      return Math.max(this.current - Math.floor(NUM_OF_PAGER_BUTTONS / 2), 1) > this.length - NUM_OF_PAGER_BUTTONS
        ? Math.max(this.length - NUM_OF_PAGER_BUTTONS + 1, 1)
        : Math.max(this.current - Math.floor(NUM_OF_PAGER_BUTTONS / 2), 1)
    },
    pagesEnd() {
      return Math.min(this.current + Math.floor(NUM_OF_PAGER_BUTTONS / 2), this.length) < NUM_OF_PAGER_BUTTONS
        ? Math.min(NUM_OF_PAGER_BUTTONS, this.length)
        : Math.min(this.current + Math.floor(NUM_OF_PAGER_BUTTONS / 2), this.length)
    },
    length() {
      return this.total
    },
    last() {
      return this.total - 1
    },
    loadMoreCount: function() {
      // TODO: Refactor this nonsense. At penultimate page for example only two last items should be loaded, not count of `perPage`.
      return this.perPage
    },
  },
  methods: {
    getUrlWithPage(url, page) {
      if (page === 0) {
        return url
      }
      if (url.indexOf('?') !== -1) {
        return `${url}&${this.urlParameterName}=${page}`
      } else {
        return `${url}?${this.urlParameterName}=${page}`
      }
    },
    goToPage(e, page) {
      if (!e.metaKey) {
        e.stopPropagation()
        e.preventDefault()
        this.$emit('changePage', page)
        if (this.scrollToId) {
          this.$scrollTo(`#${this.scrollToId}`)
        }
      }
    },
    loadMore() {
      this.$refs.loadMoreButton.blur()
      this.$emit('loadMore')
    },
  },
}
