import { get } from 'lodash'
import AppImage from '@theme/components/atom/AppImage'
import AppTitle from '@theme/components/atom/AppTitle'
import Carousel from '@theme/components/utils/Carousel'
import Pagination from '@theme/components/molecule/Pagination'
import ProductBox from '@theme/components/product/ProductBox'
import SubCategoryBox from '@theme/components/category/SubCategoryBox'
import UtilityGridMixin from '~/mixins/UtilityGridMixin'
import Wysiwyg from '@theme/components/utils/Wysiwyg'
import blockComponents from '@site/blocks'

export default {
  name: 'ContentBuilder',
  components: {
    AppImage,
    AppTitle,
    Carousel,
    Pagination,
    ProductBox,
    SubCategoryBox,
    Wysiwyg,
  },
  mixins: [UtilityGridMixin],
  props: {
    contentBlocks: Array,
    blocks: Array,
    inRow: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
    },
    page: {
      type: Number,
    },
    paginationEnabled: {
      type: Boolean,
      default: false,
    },
    loadedEntities: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    builderBlocksComponentsByType() {
      const builderBlocksComponentsByType = {}
      for (let component in blockComponents) {
        builderBlocksComponentsByType[blockComponents[component].cmsSettings.type] = blockComponents[component]
      }
      return builderBlocksComponentsByType
    },
    paginatedContentBlocks() {
      if (this.paginationEnabled) {
        return this.contentBlocks.slice((this.page - 1) * this.perPage, this.perPage * this.page)
      }
      return this.contentBlocks
    },
    totalPages() {
      if (this.paginationEnabled) {
        return Math.ceil(this.contentBlocks.length / this.perPage)
      }
      return 1
    },
  },
  methods: {
    getHtmlContent(block) {
      const value = get(block, 'value')
      if (!value) {
        return false
      } else {
        if (typeof value === 'string') {
          return value
        } else {
          return get(value, `[${this.$i18n.locale}]`, false)
        }
      }
    },
    addHeadingTitleClassesToHtml(html) {
      if (typeof html.value === 'object') {
        Object.keys(html.value).forEach(lang => {
          html.value[lang] = html.value[lang].replace('<h1>', '<h1 class="Title Title--aplha">')
          html.value[lang] = html.value[lang].replace('<h2>', '<h2 class="Title Title--beta">')
          html.value[lang] = html.value[lang].replace('<h3>', '<h3 class="Title Title--gama">')
          html.value[lang] = html.value[lang].replace('<h4>', '<h4 class="Title Title--delta">')
          html.value[lang] = html.value[lang].replace('<h5>', '<h5 class="Title Title--epsilon">')
        })
      }
      return html
    },
    getRelatedProducts(block) {
      const { products } = block.value || {}
      if (products && products.length && this.loadedEntities.products) {
        return products.reduce(
          (acc, product) => [
            ...acc,
            ...(this.loadedEntities.products[product.entityId]
              ? [
                  {
                    ...product,
                    entity: this.loadedEntities.products[product.entityId],
                  },
                ]
              : []),
          ],
          [],
        )
      } else {
        return []
      }
    },
    changePage(page) {
      page = page || 0
      if (this.paginationEnabled) {
        this.$router.push({
          path: this.$route.path,
          query: { ...((this.$route || {}).query || {}), p: page === 0 ? undefined : page + 1 },
        })
      }
    },
  },
}
